.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

.nav {
  height: calc(var(--header-height) + 1.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}
.nav__logo-img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid var(--title-color-dark);
  background-color: var(--container-color);
  padding: 1.5px;
  transition: 1s;
  animation: logo 3s ease-in-out;
  cursor: pointer;
}
.nav__logo-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

@keyframes logo {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.nav__logo-img:hover {
  background-color: var(--container-color-dark);
  border: 1px solid var(--title-color);
  animation: logo 3s ease-in-out infinite;
  cursor: pointer;
}

.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-meduim);
  cursor: pointer;
}

.nav__list {
  display: flex;
  column-gap: 2rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-meduim);
  transition: 0.8s;
  padding: 0.3rem;
  cursor: pointer;
}
.nav__icon,
.nav__close,
.nav__toggle {
  display: none;
}

/* Ative link */
.nav__link:hover {
  color: var(--title-color-dark);
  animation: activeLink 0.5s ease-in-out;
  border-bottom: 2px solid var(--title-color);
  font-weight: var(--font-normal);
}
/* React scroll active link */
.nav__item .active {
  color: var(--title-color-dark);
  border-bottom: 2px solid var(--title-color);
  font-weight: var(--font-normal);
  animation: activeLink 0.5s ease-in-out;
}

@keyframes activeLink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
/* Change backgroud cheader */
.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

/* ========== Media query ==========  */

@media screen and (max-width: 768px) {
  .header {
    top: initial;
    bottom: 0;
  }
  .nav {
    height: var(--header-height);
  }
  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 0.3s;
  }
  /* Show menu */
  .show-menu {
    bottom: 0;
  }
  .nav__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
  .nav__icon {
    font-size: 1.2rem;
  }
  .nav__close {
    position: absolute;
    right: 1.3rem;
    bottom: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color);
  }
  .nav__close:hover {
    color: var(--title-color-dark);
  }
  .nav__toggle {
    font-size: 1.1rem;
    cursor: pointer;
  }
  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: block;
  }

  .slider {
    position: absolute;
    right: 50px;
  }
  .switch-icon {
    width: 100%;
    position: absolute;
    text-align: center;
    margin-bottom: 1rem;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 350px) {
  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }
  .nav__list {
    column-gap: 0;
  }
}

/* Dark mode */

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin: 0 0.75rem;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #b6b6b6;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: var(--body-color);
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(25px);
  background-color: var(--body-color);
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: var(--title-color);
}
