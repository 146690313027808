.center {
  position: absolute;
  font-family: "Raleway", sans-serif;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 350px;
  height: 280px;
  padding: 30px 20px;
  background: #f5f5f5;
  border-radius: 10px;
  box-sizing: border-box;
  z-index: 2;
  text-align: center;
  opacity: 0;
  top: -200%;
  transform: translate(-50%, -50%) scale(0.5);
  transition: 300ms ease-in-out, top 1000ms ease-in-out,
    transform 1000ms ease-in-out;
}
.popup__active {
  opacity: 1;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 300ms cubic-bezier(0.18, 0, 89, 0.43, 1.19);
}

.popup .popup__icon .uil-icon {
  margin: 5px 0px;
  width: 50px;
  height: 50px;
  border: 2px solid #34f234;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
}

.popup .popup__icon .uil-icon {
  color: #34f234;
  font-size: 30px;
}
.popup__title {
  color: #222;
  margin: 5px 0px;
  font-size: 1.17em;
  font-weight: 600;
}

.popup .popup__description {
  color: #222;
  font-size: 15px;
  padding: 5px;
}

.dissmiss__btn {
  margin-top: 15px;
}

.dissmiss__btn button {
  padding: 10px 20px !important;
  background: var(--title-color);
  color: var(--container-color);
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  transition: all 300mx ease-in-out;
}

.dissmiss__btn button:hover {
  background-color: var(--container-color);
  color: var(--title-color);
}
